import React, { Component } from "react"
import LayoutHome from "../components/LayoutHome"
import { graphql } from "gatsby"
import BasicText from "../components/BasicText"
import Slider from "../components/Slider"
import css from "./stylesheets/index.module.scss"
import CommonButton from "../components/CommonButton"
import Seo from "../components/Seo"
import AnimatedLogo from "../components/AnimatedLogo"
import ScrollDown from "../components/ScrollDown"
import Img from "gatsby-image"
import IconFb from "../images/icon_fb.svg"

class PageIndex extends Component {
  constructor(props) {
    super(props)
    this.doClickLangButton = this.doClickLangButton.bind(this)
  }

  doClickLangButton() {
    let layoutBasic = this.ref.layoutBasicInstance
    if (typeof layoutBasic !== "undefined" && layoutBasic !== null) {
      layoutBasic.ref.globalNavigationInstance.openLanguageMenu()
    }
  }

  render() {
    let srcList = []
    for (let i = 0; i < 4; i += 1) {
      srcList.push([
        this.props.data["slideSpImage" + (i + 1)].childImageSharp.fluid,
        {
          ...this.props.data["slideImage" + (i + 1)].childImageSharp.fluid,
          media: `(min-width: 835px)`,
        },
      ])
    }

    this.ref = {}

    return (
      <div className={css.topPage}>
        <Seo title={`HOME`} />
        <LayoutHome reference={this.ref} lang={`ja`}>
          <Slider intro={true}>
            <Img
              fluid={srcList[0]}
              objectFit="cover"
              objectPosition="50% 50%"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
            <Img
              fluid={srcList[1]}
              objectFit="cover"
              objectPosition="50% 50%"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
            <Img
              fluid={srcList[2]}
              objectFit="cover"
              objectPosition="50% 50%"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
            <Img
              fluid={srcList[3]}
              objectFit="cover"
              objectPosition="50% 50%"
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </Slider>
          <div>
            <section className={css.intro}>
              <h1>
                <AnimatedLogo />
              </h1>
              <ScrollDown
                style={{
                  position: "absolute",
                  bottom: "2vh",
                }}
                to={`#TopContent`}
              />
            </section>
            <BasicText>
              <p>
                <a
                  className={
                    css.facebookLink + ` withoutBlankIcon withoutAnimation`
                  }
                  href={this.props.data.site.siteMetadata.facebookLink}
                  target={`_blank`}
                >
                  <img src={IconFb} alt="facebook" />
                  <span>Facebook で最新情報発信中</span>
                </a>
              </p>

              <hr />

              <h2 id={`TopContent`}>WHAT is KAIGO?</h2>
              <p>
                こんにちは！
                <br />
                『KAIGO in
                JAPAN』は、日本で介護の仕事をしようと考えている人のためのウェブサイトです。介護の仕事や日本での生活について、みなさんのさまざまな疑問に答え、先輩たちのリアルな声を届けたいと思っています。
                <br />
                日本で働いている先輩たちのインタビュー、日本と介護についての基本情報、よくある質問などがあります。
                <br />
                「やさしい日本語（Easy
                Japanese）」「日本語」「英語」のなかから言語を選んで、ぜひ読んでみてください。
              </p>
              <p className={`commonButton`}>
                <button onClick={this.doClickLangButton}>
                  <div>
                    <span>LANGUAGE</span>
                  </div>
                </button>
              </p>
              <h3 className={css.Heading3h}>
                介護施設の1日 <small>(3:47)</small>
              </h3>

              <div className={`fixedRatioBox is-16x9`}>
                <iframe
                  src="https://www.youtube.com/embed/i8hNvS-DbTk"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="介護施設の1日"
                ></iframe>
              </div>

              <CommonButton to={`/ja/what-is-kaigo/`}>MORE</CommonButton>

              <hr />

              <h2>INTERVIEW</h2>
              <p>
                日本で働いている先輩たちへのインタビューです。先輩・後輩、同僚、夫婦などさまざまな関係のふたりに話を聞きました。また、働いている施設と、暮らしている家を見せてもらいました。
              </p>
              <CommonButton to={`/ja/interview/`}>MORE</CommonButton>

              <hr />

              <h2>BASIC INFORMATION</h2>
              <p>
                日本はどんな国？ 日本で介護の仕事をするには？
                <br />
                日本の基本的な情報や、介護の仕事をするための4つの在留資格などを紹介します。
              </p>
              <CommonButton to={`/ja/basic-information/`}>MORE</CommonButton>

              <hr />

              <h2>FAQ</h2>
              <p>
                よくある質問です。みなさんの生活の役に立つ団体や、サービスなども紹介しています。
              </p>
              <CommonButton to={`/ja/faq/`}>MORE</CommonButton>
            </BasicText>
          </div>
        </LayoutHome>
      </div>
    )
  }
}

export default PageIndex

export const query = graphql`
  query {
    site {
      siteMetadata {
        facebookLink
      }
    }
    slideImage1: file(relativePath: { eq: "slider/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slideSpImage1: file(relativePath: { eq: "slider/01_sp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    slideImage2: file(relativePath: { eq: "slider/02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slideSpImage2: file(relativePath: { eq: "slider/02_sp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    slideImage3: file(relativePath: { eq: "slider/04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slideSpImage3: file(relativePath: { eq: "slider/04_sp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    slideImage4: file(relativePath: { eq: "slider/05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slideSpImage4: file(relativePath: { eq: "slider/05_sp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
